<template>
      <div class="bg d-flex row justify-content-center align-items-center">
        <div class="">
            <h1 class="title title-1">
                <strong>Ibancar, el mejor préstamo con garantía de auto</strong>
            </h1>
        </div>
      </div>
          <div class="container-fluid pt-5">
            <div class="row justify-content-center pb-3">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10">
                    <div class="card">
                            <div class="card-body">
                                <div class="row align-items-center justify-content-center">
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 text-center">
                                        <img width="331" height="220" class="img-fluid p-3" src="@/assets/img/chico_conduciendo_auto.png" alt="ibancar">
                                    </div>
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-10 col-xl-6 col-xxl-6 padding">
                                        <h2 id="noticia1"  class="h4-orange pb-3"><strong>Ibancar, te ayudamos en lo importante</strong></h2>
                                        <p class="card-text">
                                            Con nuestro préstamo, tu auto es tuyo y siempre será tuyo. 
                                        </p>
                                        <p class="card-text">
                                            Hemos renovado completamente el modelo de préstamo con garantía de auto. Nuestro proceso de solicitud es rápido y sencillo, puedes completarlo desde cualquier dispositivo.
                                        </p>
                                        <p class="card-text">
                                            Además, todo el proceso se realiza de manera 100% en línea, sin necesidad de salir de casa. 
                                            </p>
                                            <p class="card-text"><strong>Tu auto sólo se utiliza como garantía para el préstamo,
                                            brindándote la tranquilidad de mantener la propiedad de tu vehículo.</strong>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          <div class="container-fluid pt-5">
            <div class="row justify-content-center pb-3">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10">
                    <div class="card">
                            <div class="card-body">
                                <div class="row align-items-center justify-content-center">
                                    <!-- Imagen solo se muestra en pantallas medianas y pequeñas Mobile-->
                                    <div id="fotoV" class="col-xs-12 col-sm-12 col-md-12 col-lg-10 col-xl-6 col-xxl-6 text-center">
                                        <img width="331" height="220" class="img-fluid p-2" src="@/assets/img/quienessomosf2.webp" alt="ibancar">
                                    </div>
                                    <!--  -->
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-10 col-xl-6 col-xxl-6 padding">
                                        <h2 id="noticia2"  class="h4-orange"><strong>Miles de clientes en 5 años</strong></h2>
                                        <p class="card-text">
                                            Desde hace más de 5 años, hemos estado apoyando a personas como tú a obtener el dinero que necesitan.
                                        </p>
                                        <p class="card-text">
                                            Gracias a nuestro equipo con amplia experiencia en el sector financiero, te brindaremos orientación paso a paso para obtener el préstamo que mejor se adapte a tus necesidades. Nos aseguraremos de que comprendas
                                        todos los detalles antes de firmar cualquier acuerdo.</p>
                                        <p class="card-text">
                                            Hemos otorgado préstamos a miles de personas, y nos enorgullece poder ofrecer diferentes opciones de flexibilidad, como periodos de carencia y dias de cortesía, para facilitar
                                            el pago en momentos difíciles. Queremos asegurarnos de que puedas cumplir con tus obligaciones de pago de manera cómoda y sin estrés.
                                        </p>
                                    </div>
                                    <!-- Imagen se muestra en pantallas grandes Desktops -->
                                    <div id="fotoH" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 text-center">
                                        <img width="331" height="220" class="img-fluid p-3" src="@/assets/img/quienessomosf2.webp" alt="ibancar">
                                    </div>
                                    <!--  -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          
          <div class="container-fluid pt-5">
            <div class="row justify-content-center pb-3">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10">
                    <div class="card">
                            <div class="card-body">
                                <div class="row align-items-center justify-content-center">
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-10 col-xl-6 col-xxl-6 text-center">
                                        <img width="331" height="236" class="img-fluid p-2" src="@/assets/img/image_555.webp" alt="ibancar">
                                    </div>
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-10 col-xl-6 col-xxl-6">
                                        <h2 id="noticia3" class="h4-orange"><strong>¿Por qué seguimos creciendo?</strong></h2>
                                        <p class="card-text">
                                            <ul class="lista-ventajas">
                                                <li>
                                                    <span class="green-text pr-2">✓</span> No nos quedamos con tu auto
                                                </li>
                                                <li>
                                                    <span class="green-text pr-2">✓</span> Somos una empresa del sector financiero, no tenemos la intención de quedarnos con tu auto
                                                </li>
                                                <li>
                                                    <span class="green-text pr-2">✓</span> Te asignamos un ejecutivo para ayudarte en toda la vida del préstamo
                                                </li>
                                                <li>
                                                    <span class="green-text pr-2">✓</span> Contamos con un área privada de clientes con toda la información que necesitas de tu préstamo
                                                </li>
                                                <li>
                                                    <span class="green-text pr-2">✓</span> Solicitud 100% en línea y gestión del préstamo en 48 horas
                                                </li>
                                                <li>
                                                    <span class="green-text pr-2">✓</span>Apoyo del equipo de inversores Ibancar
                                                </li>
                                            </ul>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid pt-3 pb-5">
                <div class="row justify-content-center pb-4">
                    <h5>¿Sigues teniendo dudas y quieres buscar más información?</h5>
                </div>
                <div class="row justify-content-center">
                    <button type="button" class="btn-orange" @click="goTo('/')">Volver a Inicio</button>
                </div>
            </div>


<Slogan />

</template>

<script>
import Slogan from '@/components/Slogan.vue'

export default {
  name: "QuienesSomosS1",
  components:{
      Slogan
  },
  methods: {
      goTo(route) {
            this.$router.push({path: route});
      }
  }
};
</script>

<style scoped lang="scss">

.title {
  width: 100%;
  font-family: 'Montserrat';
  font-display: swap;
  font-style: normal;
  line-height: auto;
  text-align: center;
  color: #FFFFFF;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.text-orange {
    color: #FFA500;
    font-size: 1.25rem;
    font-weight: 550;
}
.h4-orange {
    color: #FFA500;
    font-weight: 550;
    font-size: 1.5rem;
}
.card {
    line-height: 1.8rem;
}
.lista-ventajas {
    list-style: none;
    line-height: 2rem;
    .green-text {
        font-weight: bold;
        line-height: 4vh;
        color: #4AC068;
    }   
}
.lista-documentacion {
    line-height: 5.5vh;
}

  // Resposive para pantallas a partir de tamaño medio a pequeño

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

@include media-breakpoint-between(xs, md) {
    .bg {
        margin-top: 67px;
    }
    .title-1{
        font-size: 1.3rem;
        line-height: 40px;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    .title-h2 {
        font-size: 18px;
        line-height: 33px;
    }
    .h4-title {
        font-size: 1.15rem;
        text-decoration: underline;
        font-weight: 600;
    }
    .lista-ventajas {
        padding: 0;
    }
    h4 {
        &.text-orange {
            text-align: center;
        }
    }
    #fotoH {
        display: none;
    }

}

@include media-breakpoint-between(md, lg) {
    .bg {
        margin-top: 91px;
    }
    .title-1{
    font-size: 1.5rem;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    .title-h2 {
        font-size: 1.25rem;
        line-height: 55px;
    }
    .card {
    background-color: #fbfbfb;
    height: auto;
        p{
            line-height: auto;
            font-size: 18px;
        }
    }
    .h4-title {
        font-size: 1.25rem;
        text-decoration: underline;
        font-weight: 600;
    }
    .img-fluid {
        width: 70%;
    }
    .h4-orange {
        text-align: center;
    }
    ul{
        li{
            font-size: 18px;
        }
    }
    .lista-ventajas {
        padding: 0;
    }
    #fotoH {
        display: none;
    }


}

@include media-breakpoint-between(xl, xxl) {
    .bg {
        margin-top: 8rem;
    }
    .title-1{
    font-size: 1.75rem;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    .title-h2 {
        font-size: 1.75rem;
        line-height: 50px;
    }
    .card {
    background-color: #fbfbfb;
    height: auto;
        p {
            line-height: auto;
            font-size: 18px;
        }
    }
    #fotoV {
        display: none;
    }

    .lista-ventajas {
        padding: 0;
    }
    .img-fluid {
        width: 90%;
    }

}

</style>
